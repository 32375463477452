/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Scripts/utils/accordion
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
'use strict'

const Accordion = {
  init: () => {
    let accordions = document.querySelectorAll('.js-accordion')

    accordions.forEach((table) => {
      let rows = table.querySelectorAll('.accordion--item')

      rows.forEach((row) => {
        let question = row.querySelector('.question')
        let answer = row.querySelector('.answer')
        let height = answer.scrollHeight

        question.addEventListener('click', ()=> {
          if (row.classList.contains('active')) {
            answer.style.height = '0px'
            row.classList.remove('active')
          } else {
            answer.style.height = height + 'px'
            row.classList.add('active')
          }

          rows.forEach((otherRow) => {
            if (otherRow != row) {
              let rowAnswer = otherRow.querySelector('.answer')

              rowAnswer.style.height = '0px'
              otherRow.classList.remove('active')
            }
          })
        })
      })
    })
  }
}

export default Accordion

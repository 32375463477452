/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Scripts/layout/menu
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
'use strict'

import Swiper from 'swiper'

const Slider = {
  init: () => {
    let home = document.querySelector('.home')

    if (home) {
      let homeParams = {
        slidesPerView: 1,
        loop: true,
        // navigation: {
        //   nextEl: '.swiper-next',
        //   prevEl: '.swiper-prev'
        // }
      }

      if (document.getElementById('slider-image')) {
        homeParams.autoplay =  {
          disableOnInteraction: true
        }
        homeParams.effect = 'fade'
      }

      new Swiper('.js-slider-hero', homeParams)
    }

    const sliders = document.querySelectorAll('.js-slider')
    if (sliders.length > 0) {
      Array.from(sliders).forEach( slider => {
        const buttonPrev = slider.querySelector('.swiper-button-prev')
        const buttonNext = slider.querySelector('.swiper-button-next')
        new Swiper(slider, {
          slidesPerView: 1,
          direction: 'horizontal',
          loop: true,
          navigation: {
            nextEl: buttonNext,
            prevEl: buttonPrev
          }
        })
      })
    }
  }
}

export default Slider

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Scripts/utils/cookies
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
'use strict'

import jsCookie from 'js-cookie'

const Cookies = {
  init: () => {
    const cookie = jsCookie.get('mts-cookie')
    const banner = document.querySelector('#cookies')

    if (!banner) {
      return
    }

    if (cookie != 'yes') {
      banner.classList.add('active')

      const button = banner.querySelector('button')

      button.addEventListener('click', () => {
        banner.classList.remove('active')

        jsCookie.set('mts-cookie', 'yes', {
          expires: 14
        })
      })
    }
  }
}

export default Cookies

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Scripts/utils/Revealer
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

class Revealer {
  constructor(
    animationName,
    animationDelay,
    threshold
  ) {
    this.defaults = {
      animationName: animationName || 'default',
      animationDelay: animationDelay || 0,
      threshold: threshold || 0.1
    }
  }

  customSetting(target, name) {
    let value = null
    let settings = target.dataset.r.split(',')

    settings.forEach(
      (setting) => {
        if (setting.split(':')[0] === name) {
          value = setting.split(':')[1]
        }
      }
    )

    return value
  }

  setAnimation(target) {
    let name = this.customSetting(target, 'name') || this.defaults.animationName;
    let delay = this.customSetting(target, 'delay') || this.defaults.animationDelay;

    if (name !== null) {
      target.classList.add('r__' + name)
    }

    if (delay !== null) {
      target.style.animationDelay = delay + 'ms'
    }
  }

  setObserverOptions(target) {
    let threshold = this.customSetting(target, 'threshold') || this.defaults.threshold

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: Number(threshold)
    }

    return options
  }

  reveal() {
    let targets_R = document.querySelectorAll('[data-r]')

    targets_R.forEach(
      (target) => {
        this.setAnimation(target)

        let observerOptions = this.setObserverOptions(target)

        let observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach(
              (entry) => {
                if (entry.intersectionRatio > observerOptions.threshold) {
                  entry.target.classList.add('revealed')
                  observer.unobserve(entry.target)
                }
              }
            )
          },
          observerOptions
        )

        observer.observe(target)
      }
    )
  }

  init() {
    document.body.classList.add('r__loaded')

    this.reveal()
  }
}

export default Revealer

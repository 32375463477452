/**
 * Init smoothScroll handling on import.
 *
 * @module smoothScroll
 * @example
 * // import it in your Javascript file
 * import "path/to/smoothScroll.js"
 * @example
 * <!-- Use it on any element -->
 * <a class="smoothscroll" href="#section-1">Scroll to section 1</a>
 * <span class="smoothscroll" data-target="#section-2">Scroll to section 2</span>
 * */

export default ((selector) => {
  Array.from(document.querySelectorAll(selector)).forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      console.log(window.location.pathname, link.getAttribute('data-url'));
      if (window.location.pathname === link.getAttribute('data-url')) {
        let anchor = link.getAttribute('data-target')
        console.log(anchor);
        var anchorReplace = anchor.replace(/\//g, '')
        document
          .querySelector(anchorReplace)
          .scrollIntoView({ behavior: 'smooth', block: 'start' })
      } else {
        return true
      }
    })
  })
})('.smoothscroll')

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Scripts/utils/maps
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
'use strict'

const Maps = {
  mapTile: () => {
    return new ol.layer.Tile({
      source: new ol.source.OSM({
        attributions: '',
        url: 'https://{a-c}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
      })
    })
  },
  mapView: (longitude, latitude, zoom) => {
    return new ol.View({
      center: ol.proj.fromLonLat(
        [
          longitude,
          latitude
        ]
      ),
      zoom: zoom
    })
  },
  map: (element, longitude, latitude, zoom) => {
    return new ol.Map({
      target: element,
      view: Maps.mapView(longitude, latitude, zoom),
      interactions: ol.interaction.defaults(
        {
          mouseWheelZoom: false
        }
      ),
      layers: [
        Maps.mapTile()
      ]
    })
  }
}

export default Maps

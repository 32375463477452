export default (selector => {
  if (selector.length > 0) {
    document.querySelectorAll('[data-modal-target]').forEach(button => {
      button.addEventListener('click', function(e) {
        e.preventDefault()
        const getTarget = button.dataset.modalTarget
        if (document.querySelector('.js-modal.is-open')) {
          document.querySelector('.js-modal.is-open').classList.remove('is-open')
        } else {
          document.getElementById(getTarget).classList.add('is-open')
        }
      })
    })

    document.querySelectorAll('.modal-overlay').forEach(overlay => {
      overlay.addEventListener('click', function() {
        document.querySelector('.js-modal.is-open').classList.remove('is-open')
      })
    })
  }
})(".js-modal");

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Scripts/main
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
'use strict'

import Cookies from './utils/cookies'
import './utils/modal'
import './utils/smoothScrollAnchor'
import Parallax from './utils/parallax'
import Revealer from './utils/revealer'
import responsiveTable from './utils/responsiveTable'
import Accordion from './utils/accordion'

import Menu from './layouts/menu'
import Slider from './layouts/slider'
import Contact from './layouts/contact'

window.addEventListener("DOMContentLoaded", () => {
  let R = new Revealer()

  R.init()

  // Utils
  Cookies.init()

  // responsiveTable
  responsiveTable.init()

  // Accordion
  Accordion.init()

  // Contact
  Contact.init()

  // Menu
  Menu.mobile()
  Menu.sticky()

  // Parallax
  Parallax.init()

  // Slider
  Slider.init()

})

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Scripts/layout/menu
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
'use strict'

import SimpleParallax from 'simple-parallax-js'

const Parallax = {
  init: () => {
    let parallaxes = document.querySelectorAll('.parallax')

    parallaxes.forEach((block) => {
      let img = block.querySelector('img')

      new SimpleParallax(img, {
        scale: 1.2,
        overflow: false
      })
    })
  }
}

export default Parallax

/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Scripts/layout/menu
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */
'use strict'

const Menu = {
  mobile: () => {
    let btn = document.querySelector('.header-bar button')
    let menu = document.querySelector('.header-menu .menu')
    let submenus = document.querySelectorAll('.header-menu .menu .submenu')

    if (!window.matchMedia("(max-width: 1024px)").matches) {
      return
    }

    btn.addEventListener('click', () => {
      if (menu.classList.contains('hidden')) {
        btn.classList.add('active')
        menu.classList.remove('hidden')
      } else {
        btn.classList.remove('active')
        menu.classList.add('hidden')

        submenus.forEach((submenu) => {
          submenu.classList.remove('active')
        })
      }
    })

    submenus.forEach((submenu) => {
      submenu.addEventListener('click', () => {
        console.log('ok')
        if (submenu.classList.contains('active')) {
          submenu.classList.remove('active')
        } else {
          submenu.classList.add('active')
        }
      })
    })
  },
  sticky: () => {
    if (window.matchMedia("(max-width: 1024px)").matches) {
      return
    }

    let header = document.querySelector('header')
    let headerTop = header.querySelector('.header-top')
    let headerTopHeight = headerTop.clientHeight
    let headerMenu = header.querySelector('.header-menu')
    let headerMenuHeight = headerMenu.clientHeight

    window.onscroll = () => {
      if (window.scrollY > headerTopHeight) {
        header.classList.add('sticky')
        headerTop.style.marginBottom = headerMenuHeight + 'px'
      } else {
        header.classList.remove('sticky')
        headerTop.style.marginBottom = 0 + 'px'
      }
    }
  }
}

export default Menu
